var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "rootRef",
    staticClass: "splitpanes d-flex",
    class: {
      'splitpanes--vertical': _setup.disabled,
      'splitpanes--horizontal': !_setup.disabled,
      'splitpanes--resizing': _setup.isDragging
    }
  }, [_vm.$slots.first ? _c('div', {
    ref: "firstRef",
    staticClass: "splitpanes__pane",
    style: {
      width: "".concat(!_setup.disabled ? _setup.width : 100, "%")
    }
  }, [_vm._t("first")], 2) : _vm._e(), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$slots.first && _vm.$slots.second,
      expression: "$slots.first && $slots.second"
    }],
    ref: "splitterRef",
    staticClass: "splitpanes__splitter"
  }), _vm._v(" "), _vm.$slots.second ? _c('div', {
    ref: "secondRef",
    staticClass: "splitpanes__pane",
    style: {
      width: "".concat(!_setup.disabled ? 100 - _setup.width : 100, "%")
    }
  }, [_vm._t("second")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };